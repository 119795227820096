/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef, useCallback, useState } from 'react';

import { css, jsx, keyframes } from '@compiled/react';

import EditionsIcon from '@atlaskit/icon-lab/core/editions';
import CreditCardIcon from '@atlaskit/icon/core/migration/credit-card--creditcard-filled';
import WarningIcon from '@atlaskit/icon/utility/warning';
import {
	Anchor,
	type AnchorProps,
	Box,
	Pressable,
	type PressableProps,
	xcss,
} from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { AnimatedGemIcon } from './AnimatedGemIcon';
import { SparkleIcon } from './SparkleIcon';

const buttonStyles = xcss({
	borderWidth: 'border.width',
	font: token('font.body'),
	borderStyle: 'solid',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.100',
	paddingInline: 'space.150',
	paddingBlock: 'space.050',
	minHeight: '32px',
	color: 'color.text',
	transitionProperty: 'color, background-color',
	transitionDuration: '200ms',
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
	fontWeight: token('font.weight.medium'),
	textDecoration: 'none',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		textDecoration: 'none',
		color: 'color.text',
	},

	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
		textDecoration: 'none',
	},
});

const brandStyles = xcss({
	color: 'color.text.brand',
	borderColor: 'color.border.brand',

	':hover': {
		color: 'color.text.brand',
	},
});

const warningStyles = xcss({
	borderColor: 'color.border.warning',
});

const dangerStyles = xcss({
	borderColor: 'color.border.danger',
});

const iconStyles = xcss({
	display: 'flex',
	// icon size cannot grow and shrink
	flexGrow: 0,
	flexShrink: 0,
	alignSelf: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 0,
	userSelect: 'none',
});

const sparkleAnimation = keyframes({
	'0%': { transform: 'scale(0)', opacity: 0 },
	'40%': { transform: 'scale(1)', opacity: 1 },
	'80%': { transform: 'scale(0)', opacity: 0 },
	'100%': { transform: 'scale(0)', opacity: 0 },
});

const sparkleStyles = css({
	flexShrink: '0',
	'&:hover': {
		// This is the only way to add the keyframes which target the inner parts of the icon
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles
		'#starTopRight': {
			animationName: sparkleAnimation,
			animationDuration: '2s',
			animationTimingFunction: 'ease-in-out',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
			transformBox: 'fill-box',
		},
		// This is the only way to add the keyframes which target the inner parts of the icon
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles
		'#starBottomRight': {
			animationName: sparkleAnimation,
			animationDuration: '2s',
			animationTimingFunction: 'ease-in-out',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
			transformBox: 'fill-box',
			// We delay this star's animation so the two stars sparkle separately
			animationDelay: '0.5s',
			// Because this star has an animation delay, we need to make it invisible to begin with
			// so it doesn't show up in its unanimated state for the first 0.5s of the animation (or however long we set the delay to).
			opacity: 0,
		},
	},
});

type EditionAwarenessBaseProps = {
	status: 'default' | 'warning' | 'danger';
	upgradeIconType?: 'gem' | 'sparkle' | 'animated-gem';
	/** Icon displayed in button, determined by status if not provided */
	icon?: 'upgrade' | 'warning' | 'danger' | 'missing-payment-details';
};

interface EditionAwarenessIconProps extends EditionAwarenessBaseProps {
	isHovering: boolean;
}

interface EditionAwarenessButtonProps
	extends Exclude<PressableProps, 'placeholder'>,
		EditionAwarenessBaseProps {}

interface EditionAwarenessLinkButtonProps
	extends Exclude<AnchorProps, 'placeholder'>,
		EditionAwarenessBaseProps {}

const ButtonIcon = (props: EditionAwarenessIconProps) => {
	const { status, upgradeIconType, icon, isHovering } = props;

	if (upgradeIconType === 'animated-gem') {
		return <AnimatedGemIcon isHovering={isHovering} />;
	}

	const UpgradeIcon = upgradeIconType === 'gem' ? EditionsIcon : SparkleIcon;

	// Cases where the icon is specified
	if (icon === 'upgrade') {
		return (
			<UpgradeIcon
				label=""
				primaryColor="var(--ds-icon-discovery)"
				color="var(--ds-icon-discovery)"
				testId={`${upgradeIconType}-icon`}
			/>
		);
	}
	if (icon === 'warning') {
		return <WarningIcon label="" color="var(--ds-icon-warning)" testId="warning-icon" />;
	}
	if (icon === 'danger') {
		return <WarningIcon label="" color="var(--ds-icon-danger)" testId="warning-icon" />;
	}
	if (icon === 'missing-payment-details') {
		return (
			<CreditCardIcon
				label=""
				color="var(--ds-icon-discovery)"
				LEGACY_size="small"
				testId="credit-card-icon"
			/>
		);
	}

	// Cases where the icon is undefined, falls back to status determining the icon
	if (status === 'warning') {
		return <WarningIcon label="" color="var(--ds-icon-warning)" testId="warning-icon" />;
	}
	if (status === 'danger') {
		return <WarningIcon label="" color="var(--ds-icon-danger)" testId="warning-icon" />;
	}

	// Default status
	return (
		<UpgradeIcon
			label=""
			primaryColor="var(--ds-icon-discovery)"
			color="var(--ds-icon-discovery)"
			testId={`${upgradeIconType}-icon`}
		/>
	);
};

export const EditionAwarenessButton = forwardRef<HTMLButtonElement, EditionAwarenessButtonProps>(
	({ children, status, upgradeIconType, icon, onMouseEnter, onMouseLeave, ...rest }, ref) => {
		const [isHovering, setIsHovering] = useState(false);

		const handleOnMouseEnter = useCallback(
			(event: any) => {
				setIsHovering(true);
				onMouseEnter && onMouseEnter(event);
			},
			[onMouseEnter, setIsHovering],
		);

		const handleOnMouseLeave = useCallback(
			(event: any) => {
				setIsHovering(false);
				onMouseLeave && onMouseLeave(event);
			},
			[onMouseLeave, setIsHovering],
		);

		return (
			<div css={sparkleStyles}>
				<Pressable
					{...rest}
					ref={ref}
					xcss={[
						buttonStyles,
						status === 'default' && brandStyles,
						status === 'warning' && warningStyles,
						status === 'danger' && dangerStyles,
					]}
					onMouseEnter={(event: any) => handleOnMouseEnter(event)}
					onMouseLeave={(event: any) => handleOnMouseLeave(event)}
				>
					<Box xcss={iconStyles}>
						<ButtonIcon
							status={status}
							upgradeIconType={upgradeIconType}
							icon={icon}
							isHovering={isHovering}
						/>
					</Box>
					{children}
				</Pressable>
			</div>
		);
	},
);

export const EditionAwarenessLinkButton = forwardRef<
	HTMLAnchorElement,
	EditionAwarenessLinkButtonProps
>(({ children, status, upgradeIconType, href, icon, onMouseEnter, onMouseLeave, ...rest }, ref) => {
	const [isHovering, setIsHovering] = useState(false);

	const handleOnMouseEnter = useCallback(
		(event: any) => {
			setIsHovering(true);
			onMouseEnter && onMouseEnter(event);
		},
		[onMouseEnter, setIsHovering],
	);

	const handleOnMouseLeave = useCallback(
		(event: any) => {
			setIsHovering(false);
			onMouseLeave && onMouseLeave(event);
		},
		[onMouseLeave, setIsHovering],
	);

	return (
		<div css={sparkleStyles}>
			<Anchor
				{...rest}
				ref={ref}
				href={href}
				xcss={[
					buttonStyles,
					status === 'default' && brandStyles,
					status === 'warning' && warningStyles,
					status === 'danger' && dangerStyles,
				]}
				onMouseEnter={(event: any) => handleOnMouseEnter(event)}
				onMouseLeave={(event: any) => handleOnMouseLeave(event)}
			>
				<Box xcss={iconStyles}>
					<ButtonIcon
						status={status}
						upgradeIconType={upgradeIconType}
						icon={icon}
						isHovering={isHovering}
					/>
				</Box>
				{children}
			</Anchor>
		</div>
	);
});
