import React, { useEffect, useRef, useState } from 'react';

import Lottie, { type AnimationItem } from 'lottie-web';

import { useIsReducedMotion } from '@atlaskit/motion';
import { Box } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';

import darkModeGemAnimationData from './assets/dark-mode-gem-animation-data.json';
import lightModeGemAnimationData from './assets/light-mode-gem-animation-data.json';

export const AnimatedGemIcon = ({ isHovering }: { isHovering: boolean }) => {
	const { colorMode } = useThemeObserver();
	const isReducedMotion = useIsReducedMotion();
	const lottieRef = useRef<any | null>(null);
	const [hasPlayedAnimation, setHasPlayedAnimation] = useState(false);
	const animationInstance = useRef<AnimationItem | null>(null);

	useEffect(() => {
		if (lottieRef.current) {
			animationInstance.current = Lottie.loadAnimation({
				container: lottieRef.current,
				renderer: 'svg',
				autoplay: !isReducedMotion,
				loop: isHovering && !isReducedMotion ? true : false,
				animationData: colorMode === 'dark' ? darkModeGemAnimationData : lightModeGemAnimationData,
			});
		}
		// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
		animationInstance.current?.addEventListener('complete', () => {
			setHasPlayedAnimation(true);
		});

		if (hasPlayedAnimation && !isHovering) {
			animationInstance.current?.stop();
		}

		return () => {
			if (animationInstance) {
				// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
				animationInstance?.current?.removeEventListener('complete');
				animationInstance?.current?.destroy();
			}
		};
	}, [colorMode, isHovering, hasPlayedAnimation, isReducedMotion]);

	return <Box ref={lottieRef} />;
};
